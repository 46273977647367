import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SearchBar() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearch = async () => {
    if (query.trim() === '') {
      return;
    }
    try {
      const response = await fetch(`https://ysdb-api.vercel.app/search?q=${query}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.length === 0) {
        alert('Query not found.');
      } else {
        setResults(data);
        setShowTable(true);
      }
    } catch (error) {
      console.error('Error searching for query:', error);
      alert('An error occurred while searching. Please try again.');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleRowClick = (result) => {
    setShowTable(false);
    if (result.gameID && result.scriptID && result.line) {
      navigate(`/gamescripts?gameID=${result.gameID}&scriptID=${result.scriptID}&line=${result.line}`);
    } else {
      console.error('Invalid result format:', result);
    }
  };

  return (
    <div className="search-bar-container">
      <div className="mb-3 w-full max-w-5xl">
        <div className="relative mb-4 flex w-full flex-wrap items-stretch">
          <input
            type="search"
            className="relative m-0 block flex-auto rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
            placeholder="Search"
            value={query}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            style={{ color: 'white' }}
          />
          <button
            type="button"
            className="relative z-[2] flex items-center px-3 py-[0.25rem] bg-transparent border-0 text-neutral-700 dark:text-neutral-200 hover:text-neutral-500 dark:hover:text-neutral-400"
            onClick={handleSearch}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5"
            >
              <path
                fillRule="evenodd"
                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {showTable && (
          <div className="overflow-y-auto border border-solid border-neutral-300 bg-white shadow-lg rounded-md mt-1">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">English Text</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Japanese Text</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Game Info</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {results.map((result, index) => (
                  <tr
                    key={index}
                    className="cursor-pointer hover:bg-gray-100"
                    onClick={() => handleRowClick(result)}
                  >
                    <td className="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900">
                      <div>{result.enChar}</div>
                      <div>{result.jpChar}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900">{result.enText}</td>
                    <td className="px-6 py-4 whitespace-normal text-sm text-gray-500">{result.jpText}</td>
                    <td className="px-6 py-4 whitespace-normal text-sm text-gray-500">
                      <div>{result.gameID}</div>
                      <div>{result.scriptID}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}