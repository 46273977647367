import React from 'react';

export default function OnClickTable({ game, onScriptClick }) {
    if (!game || !game.scripts) {
        return console.log('Nope'); // Return null if no game is selected
    }

    return (
        <table className="margin-top onClickTable">
            <thead>
                <tr>
                    <th>Filename</th>
                    <th className="thtdSizeForDynamicTable">Map</th>
                </tr>
            </thead>
            <tbody>
                {game.scripts.map((script, index) => (
                    <tr key={index} onClick={() => onScriptClick(script.filename)}>
                        <td className="filenameActivity">{script.filename}</td>
                        <td>{script.map}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}