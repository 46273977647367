import '../App.css';

import Header from '../components/navbar.jsx';

function APIdoc() {
  return (
    <div className="App">
      <div>
        <Header />
      </div>
      <header className="App-header">
        <h1>API Documentation</h1>
      </header>
    </div>
  );
}

export default APIdoc;