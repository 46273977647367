// Importing components
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// Import pages for react-router-dom
import Home from './pages/home.js'
import Gamescript from './pages/gamescripts.js'
import APIDoc from './pages/apidoc.js'

// Import CSS
import './App.css';


/////////////////////

function Routing() {
  return (
    <div className="App">
      <div>
        <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/gamescripts' element={<Gamescript />} />
          <Route path='/apidoc' element={<APIDoc />} />
          {/* <Route path='/*' element={<NoPage />} /> */}
        </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default Routing;