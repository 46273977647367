import React from 'react';
import { Link } from 'react-router-dom';

const directory = require('../scripts/directory.json');

export default function Table() {
    return (
            <table className="table-striped table-hover table" style={{ width: 259.733, tableLayout: "auto", alignSelf: "center"}}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Game</th>
                    </tr>
                </thead>
                <tbody>
                    {directory.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td>{val.gameID}</td>
                                <td>
                                    <Link className="scriptLink" to={`/gamescripts?gameID=${val.gameID}`}>
                                        {val.gameName}
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
    );
}