import { Footer } from 'rsuite'; 

export default function BFooter() {
    return (
        <>
        <Footer className="footer">
            <ul>
                <li className="footermargin">Programmed by nine</li>
            </ul>
            <span className="smallFont">
                This tool is not affiliated with Nihon Falcom in any way, shape, or form. 
            </span>
        </Footer>
        </>
    )
}