import { Navbar, Nav } from 'rsuite';
import CogIcon from '@rsuite/icons/legacy/Cog';

import 'rsuite/Navbar/styles/index.css';
import 'rsuite/Nav/styles/index.css';

export default function Header() {
    return (
        <Navbar>
            <Navbar.Brand href="/">YSDB</Navbar.Brand>
            <Nav>
            <Nav.Item href="/gamescripts">Game Scripts</Nav.Item>
            {/* <Nav.Item href="/apidoc">API Documentation</Nav.Item> */}
            </Nav>
            {/* <Nav pullRight>
            <Nav.Item icon={<CogIcon />}>Settings</Nav.Item> 
            </Nav> */}
        </Navbar>
)};

/* Add light mode/dark mode to settings as drop down toggle */

/* check rsuite documentation for that */