import React from 'react';
import '../App.css';


function groupByScene(data) {
    return data.reduce((acc, item) => {
      const scene = item.scene;
      if (!acc[scene]) {
        acc[scene] = [];
      }
      acc[scene].push(item);
      return acc;
    }, {});
  }
  
  export default function DynamicTable({ script, onFilenameClick }) {
    if (!script) {
      return null; // Return null if no script is selected
    }
  
    const groupedData = groupByScene(script);
  
    const handleRowClick = (item) => {
      if (item && item.scriptID && item.filename) {
        onFilenameClick(item.scriptID, item.filename);
      }
    };
  
    return (
      <div className="dynamtable">
        {Object.keys(groupedData).map((scene) => (
          <div key={scene}>
            <h2 className="scene">Scene {scene}</h2>
            <table key={scene} className="table-striped table-hover table m-top">
              <thead>
                <tr>
                  <th>Line</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {groupedData[scene].map((item, index) => (
                  <tr id={`line-${item.line}`} key={index} onClick={() => handleRowClick(item)}>
                    <td>{item.line}</td>
                    <td><img src={item.portrait} alt=""></img></td>
                    <td>
                      <ul>
                        <li>{item.enChar}</li>
                        <li>{item.jpChar}</li>
                      </ul>
                    </td>
                    <td>{item.enText}</td>
                    <td>{item.jpText}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    );
  }