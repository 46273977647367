import '../App.css';

import Header from '../components/navbar.jsx';
import BFooter from '../components/footer.jsx';
import Scroller from '../components/topscroll.jsx';
import SearchBar from '../components/searchbar.jsx';

function Home() {
  return (
    <div className="App">
      <div>
        <Header />
      </div>
      <header className="App-header">
        <h1>YSDB</h1>
        <p className="ptext">
          An actively maintained database for Ys script files.
        </p>
        <div className="searchBarAdjust">
          <SearchBar />
        </div>
        {/* <p className="ptext">
          Currently supports:
          <li className="litext">
            <ul>Ys Seven (EN/JP)</ul>
            <ul>Ys VIII: Lacrimosa of Dana (EN/JP)</ul>
            <ul>Ys IX: Monstrum Nox (EN/JP)</ul>
          </li>
        </p> */}
      </header>
      <div>
        <BFooter/>
      </div>
      <>
        <Scroller />
      </>
    </div>
  );
}

export default Home;